import React from 'react';

function OptionsIcon({ fill }: { fill?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="5"
      viewBox="0 0 25 5">
      <g
        id="Group_960"
        data-name="Group 960"
        transform="translate(19268 4470) rotate(90)">
        <circle
          id="Ellipse_66"
          data-name="Ellipse 66"
          cx="2.5"
          cy="2.5"
          r="2.5"
          transform="translate(-4470 19243)"
          fill={fill || '#ff426c'}
        />
        <circle
          id="Ellipse_67"
          data-name="Ellipse 67"
          cx="2.5"
          cy="2.5"
          r="2.5"
          transform="translate(-4470 19253)"
          fill={fill || '#ff426c'}
        />
        <circle
          id="Ellipse_68"
          data-name="Ellipse 68"
          cx="2.5"
          cy="2.5"
          r="2.5"
          transform="translate(-4470 19263)"
          fill={fill || '#ff426c'}
        />
        <circle
          id="Ellipse_94"
          data-name="Ellipse 94"
          cx="2.5"
          cy="2.5"
          r="2.5"
          transform="translate(-4470 19263)"
          fill={fill || '#ff426c'}
        />
      </g>
    </svg>
  );
}

export default OptionsIcon;
