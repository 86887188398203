import { useEffect, useRef, useState } from 'react';
import { EpisodeType } from '../interface';

const useAudioPlayerHook = (
  duration: number | undefined,
  isPlayerActive?: boolean,
  activeAudio?: EpisodeType | null,
) => {
  const [isPlaying, setPlaying] = useState(false);
  const [position, setPosition] = useState(0);
  const PlayerRef = useRef<any>(null);
  const ProgressBarRef = useRef<any>(null);
  const AnimationRef = useRef<any>(null);

  useEffect(() => {
    const seconds = Math.floor(PlayerRef.current.duration);
    ProgressBarRef.current.max = seconds;
  }, [PlayerRef?.current?.loadedmetadata, PlayerRef?.current?.readyState]);

  useEffect(() => {
    if (isPlayerActive) {
      if (AnimationRef?.current) {
        cancelAnimationFrame(AnimationRef?.current);
      }
      PlayerRef?.current?.play();
      AnimationRef.current = requestAnimationFrame(whilePlaying);
      setPlaying(true);
    } else {
      PlayerRef?.current?.pause();
      cancelAnimationFrame(AnimationRef?.current);
      setPlaying(false);
    }
  }, [isPlayerActive, activeAudio]);

  const handleClickPlay = () => {
    const prevValue = isPlaying;
    setPlaying(!prevValue);
    if (PlayerRef.current) {
      if (!prevValue) {
        PlayerRef?.current?.play();
        AnimationRef.current = requestAnimationFrame(whilePlaying);
      } else {
        PlayerRef?.current?.pause();
        cancelAnimationFrame(AnimationRef.current);
      }
    }
  };

  const whilePlaying = () => {
    if (ProgressBarRef?.current?.value) {
      ProgressBarRef.current.value = PlayerRef?.current?.currentTime;
    }
    changePlayerCurrentTime();
    AnimationRef.current = requestAnimationFrame(whilePlaying);
  };

  const changeRange = () => {
    PlayerRef.current.currentTime = ProgressBarRef?.current?.value;
    changePlayerCurrentTime();
  };

  const changePlayerCurrentTime = () => {
    if (duration) {
      ProgressBarRef?.current?.style?.setProperty(
        '--seek-before-width',
        `${(ProgressBarRef?.current?.value / duration) * 100}%`,
      );
    }
    setPosition(ProgressBarRef?.current?.value);
  };

  const rewindBack = () => {
    timeTravel(Number(ProgressBarRef?.current?.value) - 15);
    changeRange();
  };

  const rewindForward = () => {
    timeTravel(Number(ProgressBarRef?.current?.value) + 15);
    changeRange();
  };

  const timeTravel = (newTime: number) => {
    ProgressBarRef.current.value = newTime;
  };

  const onEnded = () => {
    PlayerRef?.current?.pause();
    setPlaying(false);
  };

  const convertDuration = (val: number) => {
    if (val >= 0) {
      return new Date(Number(val) * 1000)
        .toISOString()
        .slice(14, 19)
        .replace(':', '.');
    }
  };

  return {
    isPlaying,
    position,
    PlayerRef,
    ProgressBarRef,
    rewindBack,
    rewindForward,
    changeRange,
    onEnded,
    handleClickPlay,
    convertDuration,
  };
};

export default useAudioPlayerHook;
