import CRUD from '../base';

class EmbedPlayerCRUD extends CRUD {
  createStatistics(data: {
    advertisement: string;
    device: string;
  }) {
    const url = `${this.url}/${data?.advertisement}/create_statistics/`;
    return this.request({
      url,
      method: 'POST',
      data,
    });
  }
  updateStatistics(id: string, listening_time: number) {
    const url = `${this.url}/${id}/update_statistics/`;
    const data = { listening_time };
    return this.request({
      url,
      method: 'PATCH',
      data,
    });
  }
}

export default function embedPlayerCRUD(request: any) {
  return new EmbedPlayerCRUD({
    url: '/v2/advertisements',
    request,
  });
}
