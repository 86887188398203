import React from 'react';

function CloseIcon({ stroke }: { stroke?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.243"
      height="16.243"
      viewBox="0 0 16.243 16.243">
      <g
        id="Group_1322"
        data-name="Group 1322"
        transform="translate(2.121 2.121)">
        <line
          id="Line_145"
          data-name="Line 145"
          x2="12"
          y2="12"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeWidth="3"
        />
        <line
          id="Line_146"
          data-name="Line 146"
          y1="12"
          x2="12"
          fill="none"
          stroke={stroke}
          strokeLinecap="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
}

export default CloseIcon;
