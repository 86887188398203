class CRUD {
    url: string;
    request: any;
    constructor(config:any) {
        const { request, url } = config;
        this.url = url;
        this.request = request;
    }

    get(params:any) {
        return this.request({
            url: this.url,
            params,
        });
    }

    one(id:string, params:any) {
        return this.request({
            method: "GET",
            url: `${this.url}/${id}`,
            params,
        });
    }

    update(id:string, data:any) {
        return this.request({
            method: "PUT",
            url: `${this.url}/${id}/`,
            data,
        });
    }

    create(data:any) {
        return this.request({
            method: "POST",
            url: this.url,
            data,
        });
    }

    delete(id:string) {
        return this.request({
            method: "DELETE",
            url: `${this.url}/${id}/`,
        });
    }
}

export default CRUD

