import React, {useEffect, useRef, useState} from 'react';
import LinkedinIcon from "../../svg/LinkedinIcon";
import { LinkedinShareButton } from 'next-share';
import ChainIcon from "../../svg/ChainIcon";
import CloseIcon from "../../svg/CloseIcon";


function ShareComponent({
                            setShow,
                            theme,
                            companyId,
                            episodeId,
                            textColor,
                            bgColor,
                        }: {
    setShow(val: boolean): void,
    theme: string,
    companyId: string,
    episodeId: string,
    bgColor?: string,
    textColor?: string,
}) {
    const [isShowHint, setShowHint] = useState(false);
    const notificationRef = useRef<any>(null);
    useEffect(() => {
        if (
            bgColor &&
            textColor &&
            notificationRef?.current &&
            theme === 'custom'
        ) {
            notificationRef?.current?.style?.setProperty('--bg-color', textColor);
            notificationRef?.current?.style?.setProperty('--text-color', bgColor);
        } else if (theme !== 'custom') {
            notificationRef?.current?.style?.setProperty('--bg-color', '#3f426b');
            notificationRef?.current?.style?.setProperty('--text-color', '#FFB12D');
        }
    }, [textColor, bgColor, notificationRef?.current, theme, isShowHint]);
    useEffect(() => {
        if (isShowHint) {
            setTimeout(() => {
                setShowHint(false);
            }, 3000);
        }
    }, [isShowHint]);

    const link = `https://jobcastapp.com/company/${companyId}/?active_episode=${episodeId}`;

    const copyToClipBoard = () => {
        setShowHint(true);
        navigator.clipboard.writeText(link);
        setShowHint(true);
    }

  return (
    <div className="share_component"
         style={{
             backgroundColor: theme === 'custom' ? bgColor : '',
         }}
    >
        {isShowHint && <p className="copy_link_hint"  ref={notificationRef}>Link copied!</p>}
      <button  type='button' className="close_button"  onClick={() => setShow(false)}>
        <span className="close_button_icon">
            <CloseIcon stroke={theme === 'custom' ? textColor :theme === 'dark' ? '#DFE0E8' : '#0F102B'}/>
        </span>
      </button>
      <button type='button' className="share_component_button copy_link" onClick={copyToClipBoard}
              style={{
                  color: theme === 'custom' ? textColor : '',
              }}>
        <ChainIcon fill={theme === 'custom' ? textColor : ''} />
        Copy link
      </button>
      <span className="share_component_button"
            style={{
          color: theme === 'custom' ? textColor : '',
      }}>
          <LinkedinShareButton url={link} blankTarget={true}>
              <>
        <LinkedinIcon  fill={theme === 'custom' ? textColor : ''}/>
        <span>Share with LinkedIn</span>
                  </>
          </LinkedinShareButton>
      </span>
    </div>
  );
}

export default ShareComponent;
