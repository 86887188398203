import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Player from "./Components/Player/Player";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    },
    {
        path: "embed/:id",
        element: <Player />,
    },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);

