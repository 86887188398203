import React, {useEffect, useState} from 'react';
import IPhoneIcon from "../../svg/IPhoneIcon";
import LinkedinIcon from "../../svg/LinkedinIcon";
import SpotifyIcon from "../../svg/SpotifyIcon";
import CloseIcon from "../../svg/CloseIcon";

function OptionsComponent({
                              setShow,
                              theme,
                              bgColor,
                              textColor,
                              iconsColor,
}: {
    setShow(val: boolean): void,
    theme: string,
    bgColor?: string,
    textColor?: string,
    iconsColor?: string,
}) {
    const [privacyPolicy,setPrivacyPolicy] = useState<string | null>(null)
    const [terms,setTerms] = useState<string | null>(null)
    useEffect(() => {
            fetch(
                `https://api.jobcastapp.se/api/v1/config/`,
            )
                .then(response => response.json())
                .then(data => {
                    setPrivacyPolicy(data?.privacy_policy?.link)
                    setTerms(data?.terms_and_conditions_job_seeker?.link)
                });
    }, []);
    return (
    <div className="options_component"
         style={{
             backgroundColor: theme === 'custom' ? bgColor : '',
         }}>
      <button type='button' className="close_button" onClick={() => setShow(false)}>
          <CloseIcon stroke={theme === 'custom' ? textColor : theme === 'dark' ? '#DFE0E8' : '#0F102B'}/>
      </button>
        <a href='https://jobcast.page.link/social' target="_blank"
           rel="noopener noreferrer">
            <button type='button' className="share_component_button"
                    style={{
                        color: theme === 'custom' ? textColor : '',
                    }}>
                <IPhoneIcon  fill={theme === 'custom' ? textColor : ''}/>
                Download app
            </button>
        </a>
        <a href='https://www.linkedin.com/company/jobcast-se/' target="_blank"
           rel="noopener noreferrer">
            <button type='button' className="share_component_button"
                    style={{
                        color: theme === 'custom' ? textColor : '',
                    }}>
                <LinkedinIcon  fill={theme === 'custom' ? textColor : ''}/>
                Like on LinkedIn
            </button>
        </a>
        <a href='https://open.spotify.com/show/4gNHx4ugNzTuMLGn15iGtD?si=b44eebf63f4f4773' target="_blank"
           rel="noopener noreferrer">
            <button type='button' className="share_component_button"
                    style={{
                        color: theme === 'custom' ? textColor : '',
                    }}>
                <SpotifyIcon fill={theme === 'custom' ? textColor : ''} />
                Follow on Spotify
            </button>
        </a>

      <a
        href={privacyPolicy || "https://d1s5ya0w366ogg.cloudfront.net/static/PDFs/website-privacy_policy.pdf"}
        target="_blank"
        rel="noopener noreferrer"
        className="share_component_link share_component_privacy_policy"
        style={{
            color: theme === 'custom' ? iconsColor : '',
        }}>
        Privacy Policy
      </a>
      <a
        href={terms || "https://d1s5ya0w366ogg.cloudfront.net/static/PDFs/website-t&c-employees_jobseekers.pdf"}
        target="_blank"
        rel="noopener noreferrer"
        className="share_component_link share_component_term_of_conditions"
        style={{
            color: theme === 'custom' ? iconsColor : '',
        }}>
        Terms and conditions
      </a>
    </div>
  );
}

export default OptionsComponent;
