import React from 'react';

function RewindBack({
  fill,
  className,
}: {
  fill?: string;
  className?: string;
}) {
  return (
    <svg
      className={className}
      id="Group_959"
      data-name="Group 959"
      xmlns="http://www.w3.org/2000/svg"
      width="21.744"
      height="23.771"
      viewBox="0 0 21.744 23.771">
      <g
        id="Group_913"
        data-name="Group 913"
        transform="translate(6.412 6.107)">
        <text
          id="_15_"
          data-name="15 "
          transform="translate(-0.54 2)"
          fill={fill || '#dfe0e8'}
          fontSize="9"
          fontFamily="Montserrat-Medium, Montserrat"
          fontWeight="500">
          <tspan x="0" y="9">
            15{' '}
          </tspan>
        </text>
      </g>
      <g id="arrow-clockwise" transform="translate(0 0)">
        <path
          id="Path_283"
          data-name="Path 283"
          d="M24.124,12.422a8.623,8.623,0,0,0-5.069-5.514,9.684,9.684,0,0,0-7.8.194.941.941,0,0,1-1.22-.369.815.815,0,0,1,.4-1.133A11.405,11.405,0,0,1,24.1,8.571a9.569,9.569,0,0,1-.876,13.016A11.489,11.489,0,0,1,9.262,22.953,9.7,9.7,0,0,1,5.488,10.391a.936.936,0,0,1,1.2-.415.819.819,0,0,1,.447,1.117,8.012,8.012,0,0,0,2.243,9.82,9.606,9.606,0,0,0,10.766.842,8.161,8.161,0,0,0,3.978-9.333Z"
          transform="translate(-4.5 -0.929)"
          fill={fill || '#dfe0e8'}
          fillRule="evenodd"
        />
        <path
          id="Path_284"
          data-name="Path 284"
          d="M.264,8.918a1.018,1.018,0,0,0,1.272,0l4.5-3.808a.682.682,0,0,0,0-1.078L1.536.223a1.018,1.018,0,0,0-1.272,0,.683.683,0,0,0,0,1.078L4.123,4.57.264,7.839a.682.682,0,0,0,0,1.078Z"
          transform="matrix(-0.875, 0.485, -0.485, -0.875, 12.235, 7.995)"
          fill={fill || '#dfe0e8'}
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}

export default RewindBack;
