import React from 'react';

function IPhoneIcon({fill}: { fill?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.492"
      height="24"
      viewBox="0 0 13.492 24">
      <path
        id="smartphone-iphone"
        d="M10.91.375a2.4,2.4,0,0,1,1.827.817,2.759,2.759,0,0,1,.755,1.94V21.618a2.763,2.763,0,0,1-.755,1.94,2.4,2.4,0,0,1-1.827.817H2.581a2.4,2.4,0,0,1-1.827-.817A2.759,2.759,0,0,1,0,21.618V3.132a2.763,2.763,0,0,1,.755-1.94A2.4,2.4,0,0,1,2.581.375ZM6.746,23.252a1.532,1.532,0,0,0,1.12-.46,1.553,1.553,0,0,0,.463-1.149,1.638,1.638,0,0,0-.463-1.174,1.5,1.5,0,0,0-1.12-.485,1.414,1.414,0,0,0-1.1.485,1.688,1.688,0,0,0-.438,1.174,1.606,1.606,0,0,0,.438,1.149A1.444,1.444,0,0,0,6.746,23.252Zm4.7-4.34V3.643H2.094V18.911h9.352Z"
        transform="translate(0 -0.375)"
        fill={fill}
      />
    </svg>
  );
}

export default IPhoneIcon;
