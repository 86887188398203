import React from 'react';

function ChainIcon({ fill }: { fill?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.25"
      height="17.063"
      viewBox="0 0 22.25 17.063">
      <g
        id="Group_1508"
        data-name="Group 1508"
        transform="translate(-1193.736 -228.158)">
        <path
          id="Path_386"
          data-name="Path 386"
          d="M26.459,7.007a4.574,4.574,0,0,0-6.563,0l1.1,1.116a3.057,3.057,0,0,1,4.378,0,3.192,3.192,0,0,1,0,4.457l-6.176,6.288a3.054,3.054,0,0,1-4.374,0,3.189,3.189,0,0,1,0-4.453L15.9,13.3l-1.088-1.116L13.72,13.3a4.777,4.777,0,0,0,0,6.682,4.631,4.631,0,0,0,6.579-.024l6.176-6.288a4.777,4.777,0,0,0-.015-6.666Z"
          transform="translate(1188.165 222.539)"
          fill={fill}
        />
        <path
          id="Path_387"
          data-name="Path 387"
          d="M3.424,20.94a2.8,2.8,0,0,1,0-4.021l5.795-5.674a2.941,2.941,0,0,1,4.107,0,2.764,2.764,0,0,1,.825,2.022,2.807,2.807,0,0,1-.847,2.021l-1.536,1.525L12.8,17.819l1.536-1.5a4.205,4.205,0,0,0,0-6.036,4.425,4.425,0,0,0-6.164,0L2.373,15.954a4.193,4.193,0,0,0,0,6.036A4.4,4.4,0,0,0,5.459,23.2a4.445,4.445,0,0,0,3.1-1.248L7.531,20.941a2.941,2.941,0,0,1-4.107,0Z"
          transform="translate(1192.646 222.025)"
          fill={fill}
        />
      </g>
    </svg>
  );
}

export default ChainIcon;
