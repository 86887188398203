import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from "react-router";
import EmbedPlayer from "../Embed/EmbedPlayer";
import {EpisodeType} from "../../interface";
import './styles.scss'

function Player() {
    const { id } = useParams()
    const search = useLocation().search;
    const theme=new URLSearchParams(search).get("theme");
    const size=new URLSearchParams(search).get("size");
    const bgColor=new URLSearchParams(search).get("bg");
    const textColor=new URLSearchParams(search).get("text");
    const iconsColor=new URLSearchParams(search).get("icons");
    const [episode, setEpisode] = useState<EpisodeType | null>(null);
    useEffect(() => {
        if (id) {
            fetch(
                `https://api.jobcastapp.se/api/v2/advertisements/${id}/`,
            )
                .then(response => response.json())
                .then(data => setEpisode(data));
        }
    }, [id]);
    return (
        <>
            {episode && episode?.audio && (
                <EmbedPlayer
                    episode={episode}
                    theme={(theme as string) || 'dark'}
                    size={(size as string) || 'one'}
                    bgColor={(`#${bgColor}` as string) || ''}
                    textColor={(`#${textColor}` as string) || ''}
                    iconsColor={(`#${iconsColor}` as string) || ''}
                />
            )}
        </>
    );
}

export default Player;

