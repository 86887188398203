import React from 'react';

function RewindForward({
  fill,
  className,
}: {
  fill?: string;
  className?: string;
}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="21.744"
      height="23.771"
      viewBox="0 0 21.744 23.771">
      <g
        id="Group_958"
        data-name="Group 958"
        transform="translate(3643.855 -19159.145)">
        <g
          id="Group_913"
          data-name="Group 913"
          transform="translate(-15513.851 14866.215)">
          <text
            id="_15_"
            data-name="15 "
            transform="translate(11876.367 4301.037)"
            fill={fill || '#dfe0e8'}
            fontSize="9"
            fontFamily="Montserrat-Medium, Montserrat"
            fontWeight="500">
            <tspan x="0" y="9">
              15{' '}
            </tspan>
          </text>
        </g>
        <g id="arrow-clockwise" transform="translate(-3643.855 19159.543)">
          <path
            id="Path_283"
            data-name="Path 283"
            d="M6.62,12.422a8.623,8.623,0,0,1,5.069-5.514,9.684,9.684,0,0,1,7.8.194.941.941,0,0,0,1.22-.369.815.815,0,0,0-.4-1.133A11.405,11.405,0,0,0,6.647,8.571a9.569,9.569,0,0,0,.876,13.016,11.489,11.489,0,0,0,13.959,1.365,9.7,9.7,0,0,0,3.774-12.561.936.936,0,0,0-1.2-.415.819.819,0,0,0-.447,1.117,8.012,8.012,0,0,1-2.243,9.82,9.606,9.606,0,0,1-10.766.842A8.161,8.161,0,0,1,6.62,12.421Z"
            transform="translate(-4.5 -1.327)"
            fill={fill || '#dfe0e8'}
            fillRule="evenodd"
          />
          <path
            id="Path_284"
            data-name="Path 284"
            d="M.264.223a1.018,1.018,0,0,1,1.272,0l4.5,3.808a.682.682,0,0,1,0,1.078l-4.5,3.808a1.018,1.018,0,0,1-1.272,0,.683.683,0,0,1,0-1.078L4.123,4.571.264,1.3A.708.708,0,0,1,0,.762.708.708,0,0,1,.264.223Z"
            transform="translate(13.94 -0.399) rotate(29)"
            fill={fill || '#dfe0e8'}
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
}

export default RewindForward;
