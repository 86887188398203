import React from 'react';

function PlayIcon({fill, className}:{fill?: string, className?: string}) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="15.312" height="18.259" viewBox="0 0 15.312 18.259">
            <path id="Polygon_22" data-name="Polygon 22" d="M8.143,1.429a1,1,0,0,1,1.715,0l7.234,12.056A1,1,0,0,1,16.234,15H1.766a1,1,0,0,1-.857-1.514Z" transform="matrix(0.017, 1, -1, 0.017, 14.998, 0)" fill={fill || "#fcf3e7"}/>
        </svg>

    );
}

export default PlayIcon;
