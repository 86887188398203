import React from 'react';

function LogoIcon({ fill }: { fill?: string }) {
  return (
    <svg
      id="_Слой_1"
      data-name="Слой 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 2432 901">
      <defs>
        <clipPath id="clippath">
          <rect className="cls-4" width="2432" height="901" />
        </clipPath>
      </defs>
      <g className="cls-3">
        <g className="cls-1">
          <path
            className="cls-2"
            d="m44.22,282.85l133-24.5v312.2c0,30.8-4.9,57.16-14.7,79.1-9.8,21.93-23.92,41.53-42.35,58.8-18.44,17.26-43.75,36.4-75.95,57.4l-18.9-21.7,23.8-29.4c17.26-21.47,29.28-42.82,36.05-64.05,6.76-21.24,10.15-46.78,10.15-76.65v-224c0-11.66-3.27-20.65-9.8-26.95-6.54-6.3-15.87-9.45-28-9.45h-13.3v-30.8Zm47.25-93.8c-10.04-9.8-15.05-22.16-15.05-37.1s5.01-27.3,15.05-37.1c10.03-9.8,23.45-14.7,40.25-14.7s30.68,4.79,40.25,14.35c9.56,9.57,14.35,22.05,14.35,37.45s-4.9,27.3-14.7,37.1c-9.8,9.8-23.1,14.7-39.9,14.7s-30.22-4.9-40.25-14.7Z"
            fill={fill}
          />
          <path
            className="cls-2"
            d="m529.31,283.2c26.6,16.57,46.78,38.85,60.55,66.85,13.76,28,20.65,58.8,20.65,92.4s-6.89,65.22-20.65,93.45c-13.77,28.24-33.95,50.64-60.55,67.2-26.6,16.57-58.1,24.85-94.5,24.85s-68.6-8.28-95.2-24.85c-26.6-16.56-46.67-38.85-60.2-66.85-13.54-28-20.3-58.8-20.3-92.4s7-65.21,21-93.45c14-28.23,34.3-50.63,60.9-67.2,26.6-16.56,57.86-24.85,93.8-24.85s67.9,8.29,94.5,24.85Zm-8.4,190.75c0-57.4-8.87-102.78-26.6-136.15-17.74-33.36-42-50.05-72.8-50.05-48.07,0-72.1,41.07-72.1,123.2,0,57.4,8.86,102.9,26.6,136.5,17.73,33.6,42,50.4,72.8,50.4,48.06,0,72.1-41.3,72.1-123.9Z"
            fill={fill}
          />
          <path
            className="cls-2"
            d="m976.25,282.15c22.16,15.87,38.96,36.99,50.4,63.35,11.43,26.37,17.15,54.72,17.15,85.05,0,33.14-7.35,64.87-22.05,95.2-14.7,30.34-36.52,54.95-65.45,73.85-28.94,18.9-63.94,28.35-105,28.35-26.14,0-52.5-3.97-79.1-11.9-26.6-7.93-51.8-18.9-75.6-32.9V182.75c0-11.66-3.27-20.65-9.8-26.95-6.54-6.3-15.87-9.45-28-9.45h-13.3v-31.5l133.7-24.5v238.7c9.33-20.53,24.26-37.45,44.8-50.75,20.53-13.3,45.03-19.95,73.5-19.95,30.33,0,56.58,7.94,78.75,23.8Zm-67.2,296.45c15.63-13.76,27.41-31.61,35.35-53.55,7.93-21.93,11.9-45.26,11.9-70,0-39.2-8.87-72.33-26.6-99.4-17.74-27.06-41.54-40.6-71.4-40.6-14,0-28.12,3.15-42.35,9.45-14.24,6.3-26.49,15.75-36.75,28.35v212.8c7.46,9.8,17.38,17.85,29.75,24.15,12.36,6.3,26.48,9.45,42.35,9.45,22.86,0,42.11-6.88,57.75-20.65Z"
            fill={fill}
          />
          <path
            className="cls-2"
            d="m1252.39,287.75c-25.67,0-45.5,13.3-59.5,39.9s-21,57.64-21,93.1c0,42.94,9.68,76.65,29.05,101.15,19.36,24.5,45.38,36.75,78.05,36.75,19.6,0,36.86-4.66,51.8-14,14.93-9.33,26.13-22.4,33.6-39.2h29.4c-7,37.34-22.99,67.09-47.95,89.25-24.97,22.17-56.59,33.25-94.85,33.25-31.27,0-58.57-7.35-81.9-22.05-23.34-14.7-41.42-35.11-54.25-61.25-12.84-26.13-19.25-55.3-19.25-87.5,0-35.46,7.46-68.36,22.4-98.7,14.93-30.33,35.58-54.6,61.95-72.8,26.36-18.2,56.11-27.3,89.25-27.3,35,0,64.63,10.15,88.9,30.45,24.26,20.3,37.33,51.22,39.2,92.75h-87.5c-.47-62.53-19.6-93.8-57.4-93.8Z"
            fill={fill}
          />
          <path
            className="cls-2"
            d="m1745.54,571.95c3.5,8.87,11.78,13.3,24.85,13.3,7.93,0,16.8-1.4,26.6-4.2v25.2c-21.94,14.46-43.64,21.7-65.1,21.7-19.14,0-35-5.84-47.6-17.5s-19.84-28.46-21.7-50.4c-14.94,21.94-33.25,38.74-54.95,50.4-21.7,11.66-43.52,17.5-65.45,17.5-26.6,0-48.54-8.4-65.8-25.2-17.27-16.8-25.9-39.66-25.9-68.6,0-22.86,5.71-41.18,17.15-54.95,11.43-13.76,25.43-24.03,42-30.8,16.56-6.76,40.71-15.05,72.45-24.85,19.6-6.06,35.35-11.78,47.25-17.15,11.9-5.36,21.7-13.06,29.4-23.1,7.7-10.03,11.55-23.21,11.55-39.55,0-18.66-4.9-32.66-14.7-42-9.8-9.33-23.34-14-40.6-14-20.54,0-36.99,7.82-49.35,23.45-12.37,15.64-19.95,39.09-22.75,70.35h-87.5c5.6-40.6,22.51-71.28,50.75-92.05,28.23-20.76,64.28-31.15,108.15-31.15,41.06,0,72.33,9.34,93.8,28,21.46,18.67,32.2,45.27,32.2,79.8v175.7c0,11.2,1.75,21.24,5.25,30.1Zm-115.15-10.85c11.66-6.3,21-14.35,28-24.15v-113.4c-9.8,5.6-18.9,10.04-27.3,13.3-8.4,3.27-17.74,6.54-28,9.8-16.8,5.6-29.75,10.85-38.85,15.75-9.1,4.9-15.99,11.32-20.65,19.25-4.67,7.94-7,18.67-7,32.2,0,37.8,17.96,56.7,53.9,56.7,14.93,0,28.23-3.15,39.9-9.45Z"
            fill={fill}
          />
          <path
            className="cls-2"
            d="m1943.99,575.79c13.06,15.17,31.26,22.75,54.6,22.75,17.26,0,31.26-4.55,42-13.65,10.73-9.1,16.1-21.58,16.1-37.45,0-13.53-3.62-24.26-10.85-32.2-7.24-7.93-16.34-14.11-27.3-18.55-10.97-4.43-26.49-9.45-46.55-15.05l-14.7-4.2c-22.87-6.53-42.24-13.65-58.1-21.35-15.87-7.7-29.29-18.78-40.25-33.25-10.97-14.46-16.45-33.36-16.45-56.7,0-35.46,12.6-62.3,37.8-80.5,25.2-18.2,56.23-27.3,93.1-27.3s65.45,8.75,91.35,26.25c25.9,17.5,42.58,41.65,50.05,72.45h-87.5c-2.8-21-10.85-37.8-24.15-50.4-13.3-12.6-29.05-18.9-47.25-18.9-15.87,0-29.17,4.55-39.9,13.65-10.74,9.1-16.1,21.35-16.1,36.75,0,12.6,3.73,22.75,11.2,30.45,7.46,7.7,16.56,13.65,27.3,17.85,10.73,4.2,26.13,9.1,46.2,14.7,28.46,7.94,51.33,15.52,68.6,22.75,17.26,7.24,32.2,18.44,44.8,33.6,12.6,15.17,18.9,35.35,18.9,60.55,0,34.07-11.9,60.9-35.7,80.5-23.8,19.6-54.84,29.4-93.1,29.4-27.07,0-51.8-4.9-74.2-14.7-22.4-9.8-40.72-23.45-54.95-40.95-14.24-17.5-23.22-37.21-26.95-59.15h87.5c3.26,26.6,11.43,47.49,24.5,62.65Z"
            fill={fill}
          />
          <path
            className="cls-2"
            d="m2401.78,559.34v31.5c-39.2,24.74-74.44,37.1-105.7,37.1-26.14,0-46.79-7.12-61.95-21.35-15.17-14.23-22.75-36.75-22.75-67.55v-242.2h-56.7v-31.5c24.73-6.06,45.61-16.21,62.65-30.45,17.03-14.23,31.85-32.31,44.45-54.25h31.5v84.7h106.4v34.3h-106.4v212.8c0,18.2,3.73,32.09,11.2,41.65,7.46,9.57,22.16,14.35,44.1,14.35,14.93,0,32.66-3.03,53.2-9.1Z"
            fill={fill}
          />
        </g>
      </g>
    </svg>
  );
}

export default LogoIcon;
