import React from 'react';

function EmbedShareIcon({ fill }: { fill?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="19.529"
      viewBox="0 0 16 19.529">
      <g id="share-outline" transform="translate(-7.254 -1.086)">
        <path
          id="Path_277"
          data-name="Path 277"
          d="M17.25,13.5H19a1.681,1.681,0,0,1,1.75,1.6V22.8A1.681,1.681,0,0,1,19,24.4H8.5a1.681,1.681,0,0,1-1.75-1.6V15.1A1.681,1.681,0,0,1,8.5,13.5h1.75"
          transform="translate(1.504 -4.783)"
          fill="none"
          stroke={fill || '#ff426c'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_278"
          data-name="Path 278"
          d="M17.879,6.244,15.127,3.375,12.375,6.244"
          transform="translate(0.127 -1.289)"
          fill="none"
          stroke={fill || '#ff426c'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_279"
          data-name="Path 279"
          d="M18,19.57V9.061"
          transform="translate(-2.746 -5.542)"
          fill="none"
          stroke={fill || '#ff426c'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default EmbedShareIcon;
