import axios, { AxiosInstance } from 'axios';
import embedPlayerCRUD from './routes/embedPlayer';
const _baseURL = "https://api.jobcastapp.se/api/"

class Client {
  protected readonly instance: AxiosInstance;
  embedPlayerRouting: any;

  constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json; charset=utf-8; ',
        'Accept-Language': 'en',
      },
    });

    this.embedPlayerRouting = embedPlayerCRUD(this.instance);
  }

}

const api = new Client(_baseURL);

export default api;
export const {
  embedPlayerRouting,
} = api;
