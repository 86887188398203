import React, { useEffect, useState } from 'react';
import { EpisodeType } from '../../interface';
import { embedPlayerRouting } from '../../api';
import PlayIcon from "../../svg/PlayIcon";
import PauseIcon from "../../svg/PauseIcon";
import useAudioPlayerHook from '../../hook/useAudioPlayerHook';
import OptionsComponent from "./OptionsComponent";
import ShareComponent from "./ShareComponent";
import RewindBack from "../../svg/RewindBack";
import EmbedShareIcon from "../../svg/EmbedShareIcon";
import OptionsIcon from "../../svg/OptionsIcon";
import RewindForward from "../../svg/RewindForward";
import LogoIcon from "../../svg/Logo";

function EmbedPlayer({
  episode,
  theme,
  size,
                       bgColor,
                       textColor,
                       iconsColor
}: {
  episode: EpisodeType;
  theme: string;
  size: string;
  bgColor: string;
  textColor: string;
  iconsColor: string;
}) {
  const [isOptionsShow, setOptionsShow] = useState(false);
  const [isShareShow, setShareShow] = useState(false);
  const [identifier, setIdentifier] = useState('');
  const [lastSentDuration, setLastSentDuration] = useState(0);
  const {
    isPlaying,
    position,
    PlayerRef,
    ProgressBarRef,
    rewindBack,
    rewindForward,
    changeRange,
    onEnded,
    handleClickPlay,
    convertDuration,
  } = useAudioPlayerHook(episode?.duration);

  useEffect(() => {
    if (
      identifier &&
      Number(lastSentDuration) !== -1 &&
      Number(position) > Number(lastSentDuration) &&
      Number(position) % 5 === 0
    ) {
      const updatePosition = async () => {
        try {
          await embedPlayerRouting.updateStatistics(identifier, position);
          setLastSentDuration(position);
        } catch (e) {}
      };
      updatePosition();
    }
  }, [identifier, position]);

  useEffect(() => {
    if (
        !isPlaying &&
        identifier &&
        Number(lastSentDuration) !== -1 &&
        Number(position) > Number(lastSentDuration)
    ) {
      embedPlayerRouting.updateStatistics(identifier, Math.round(position));
    }
  }, [isPlaying]);

  useEffect(() => {
    return () => {
      if (!!identifier && !!position && Number(position) > Number(lastSentDuration) && Number(lastSentDuration) !== -1) {
        embedPlayerRouting.updateStatistics(identifier, position);
      }
    };
  }, []);

  useEffect(() => {
    if (iconsColor && ProgressBarRef?.current && theme === 'custom') {
      const hexWithAlpha = `#${iconsColor.replace('#', '')}6E`;
      ProgressBarRef?.current?.style?.setProperty('--bar-bg', hexWithAlpha);
      ProgressBarRef?.current?.style?.setProperty(
          '--seek-before-color',
          iconsColor,
      );
      ProgressBarRef?.current?.style?.setProperty(
          '--selected-knobby',
          iconsColor,
      );
      ProgressBarRef?.current?.style?.setProperty('--knobby', iconsColor);
    }
  }, [ ProgressBarRef?.current, theme]);

  const handleClickPlayLocal = async () => {
    handleClickPlay();
    if (!isPlaying && !identifier) {
      try {
        const res = await embedPlayerRouting.createStatistics({
          advertisement: episode.id,
          device: navigator.userAgent,
        });
        setIdentifier(res?.data?.id);
      } catch (e: any) { }
    }
  };

  const onEndedLocal = async () => {
    onEnded();
    if (lastSentDuration !== -1) {
      try {
        await embedPlayerRouting.updateStatistics(identifier, -1);
        setLastSentDuration(-1);
      } catch (e) {}
    }
  };

  let _iconsColor;
  switch (theme) {
    case 'dark':
      _iconsColor = '#DFE0E8';
      break;
    case 'light':
      _iconsColor = '#0F102B';
      break;
    case 'custom':
      _iconsColor = iconsColor;
      break;
  }

  return (
    <div className={`player_container ${theme} ${size}`}
    style={{
      backgroundColor: theme === 'custom' ? bgColor : ''
    }}>
      {isOptionsShow &&
      <OptionsComponent
          setShow={setOptionsShow}
          bgColor={bgColor}
          textColor={textColor}
          iconsColor={iconsColor}
          theme={theme}
      />}
      {isShareShow &&
      <ShareComponent
          setShow={setShareShow}
          companyId={episode.company.id}
          episodeId={episode.id}
          bgColor={bgColor}
          textColor={textColor}
          theme={theme}
      />}
      <div className="left_part">
        <div
          className="logo_wrapper"
          style={{
            backgroundImage: `url(${episode?.company?.logo})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <h2 className="title" style={{
          color: theme === 'custom' ? textColor : ''
        }}>{episode?.title}</h2>
        <p className="company_name" style={{
          color: theme === 'custom' ? textColor : ''
        }}>{episode?.company?.name}</p>
        <div className="embed_player">
          <audio
            src={episode?.audio || ''}
            ref={PlayerRef}
            onEnded={onEndedLocal}
          />
          <input
            type="range"
            className="progressBar"
            defaultValue="0"
            ref={ProgressBarRef}
            onChange={changeRange}
            step={0.05}
          />

          <div className="duration">
            <p className="duration_passed" style={{
              color: theme === 'custom' ? textColor : ''
            }}>{convertDuration(position)}</p>
            <p className="duration_left" style={{
              color: theme === 'custom' ? textColor : ''
            }}>
              {convertDuration(episode?.duration - position)}
            </p>
          </div>
          <div className="controls">
            <button
              type="button"
              className="rewind_back_button rewind_button"
              onClick={rewindBack}>
              <RewindBack fill={
                theme === 'custom'
                    ? textColor
                    : theme === 'dark'
                    ? '#DFE0E8'
                    : '#0F102B'
              }/>
            </button>
            {theme !== 'custom' && (
                <div className="big_oval" onClick={handleClickPlayLocal}>
                  <div className="small_oval">
                    <button type="button" className="play" style={{
                      left: isPlaying ? '1px' : '3px'
                    }}>
                      {isPlaying ? (
                              <PauseIcon/>
                          )
                          : (
                              <PlayIcon/>
                          )}
                    </button>
                  </div>
                </div>
            )}
            {theme === 'custom' && (
                <div
                    className="custom_play_button_wrapper"
                    onClick={handleClickPlayLocal}>
                  <button
                      type="button"
                      className="custom_play_button"
                      style={{
                        backgroundColor: textColor,
                      }}
                  />
                  {isPlaying ? (
                      <PauseIcon
                          fill={textColor}
                          className="pause_button_icon player_button_icon"
                      />
                  ) : (
                      <PlayIcon
                          fill={textColor}
                          className="play_button_icon player_button_icon"
                      />
                  )}
                </div>
            )}
            <button
              type="button"
              className="rewind_forward_button rewind_button"
              onClick={rewindForward}>
              <RewindForward fill={
                theme === 'custom'
                    ? textColor
                    : theme === 'dark'
                    ? '#DFE0E8'
                    : '#0F102B'
              }/>
            </button>
          </div>
        </div>
        <a
          href="https://jobcastapp.com/"
          target="_blank"
          className="logo_link"
          rel="noreferrer">
          <LogoIcon
              fill={
                theme === 'custom'
                    ? textColor
                    : theme === 'dark'
                    ? '#DFE0E8'
                    : '#0F102B'
              }
          />
        </a>
        <div className="share_options">
          <button
            type="button"
            className="embed_share"
            onClick={() => setShareShow(true)}>
            <EmbedShareIcon  fill={_iconsColor}/>
          </button>
          <button
            type="button"
            className="embed_options"
            onClick={() => setOptionsShow(true)}>
            <OptionsIcon  fill={_iconsColor}/>
          </button>
        </div>
      </div>
      <div
        className="cover_wrapper"
        style={{
          cursor: 'pointer',
          backgroundImage: episode?.cover ? `url(${episode?.cover})` : '',
          backgroundSize: episode?.cover ? `cover` : '',
          border: episode?.cover ? 'none' : '',
          backgroundPosition: 'center',
        }}/>
    </div>
  );
}

export default EmbedPlayer;
