import React from 'react';

function PauseIcon({fill, className}:{fill?: string, className?: string}) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="11" height="16.002" viewBox="0 0 11 16.002">
            <g id="Component_11_10" data-name="Component 11 – 10" transform="translate(0 0.002)">
                <g id="Group_622" data-name="Group 622" transform="translate(-21.171 -10.345)">
                    <rect id="Rectangle_793" data-name="Rectangle 793" width="4" height="16" rx="2" transform="translate(21.171 10.345)" fill={fill || "#fff"}/>
                    <rect id="Rectangle_794" data-name="Rectangle 794" width="4" height="16" rx="2" transform="translate(28.171 10.345)" fill={fill || "#fff"}/>
                </g>
            </g>
        </svg>

    );
}

export default PauseIcon;
