import React from 'react';

function LinkedinIcon({ fill }: { fill?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.796"
      height="17.459"
      viewBox="0 0 17.796 17.459">
      <path
        id="linkedin-rect"
        d="M3.494.009A3.465,3.465,0,0,0,.007,3.431V14.047a3.465,3.465,0,0,0,3.488,3.421H14.316A3.464,3.464,0,0,0,17.8,14.047V3.431A3.465,3.465,0,0,0,14.316.009ZM4.371,2.89a1.377,1.377,0,1,1-.018,2.741H4.336A1.376,1.376,0,1,1,4.371,2.89ZM12.3,6.528c1.769,0,3.094,1.134,3.094,3.571v4.549H12.7V10.4c0-1.066-.389-1.794-1.362-1.794a1.469,1.469,0,0,0-1.379.964,1.769,1.769,0,0,0-.089.643v4.431H7.185s.035-7.189,0-7.934H9.873V7.838A2.676,2.676,0,0,1,12.3,6.529ZM3.01,6.715H5.7v7.933H3.01Z"
        transform="translate(-0.007 -0.009)"
        fill={fill}
      />
    </svg>
  );
}

export default LinkedinIcon;
