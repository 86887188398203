import React from 'react';

function SpotifyIcon({ fill }: { fill?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.052"
      height="18.264"
      viewBox="0 0 19.052 18.264">
      <path
        id="spotify"
        fill={fill}
        d="M13.979,12.865a.684.684,0,0,0-.372-.606,10.983,10.983,0,0,0-5.545-1.367,17.256,17.256,0,0,0-3.56.4.578.578,0,0,0-.521.618.569.569,0,0,0,.167.41.585.585,0,0,0,.441.172,3.763,3.763,0,0,0,.459-.1,15.748,15.748,0,0,1,3.014-.321,9.672,9.672,0,0,1,4.924,1.225.874.874,0,0,0,.409.131.583.583,0,0,0,.409-.16.534.534,0,0,0,.174-.41Zm1.191-2.556a.792.792,0,0,0-.434-.725,13.48,13.48,0,0,0-6.8-1.677,14.446,14.446,0,0,0-3.759.5.718.718,0,0,0-.6.761.674.674,0,0,0,.217.505.738.738,0,0,0,.528.209,2.8,2.8,0,0,0,.459-.1A12.359,12.359,0,0,1,7.9,9.394a12.037,12.037,0,0,1,6.053,1.474,1.12,1.12,0,0,0,.472.155.734.734,0,0,0,.527-.208A.673.673,0,0,0,15.17,10.309ZM16.509,7.36a.892.892,0,0,0-.5-.833,13.15,13.15,0,0,0-3.634-1.314,20.133,20.133,0,0,0-4.254-.445,16.673,16.673,0,0,0-4.515.559.987.987,0,0,0-.477.3.847.847,0,0,0-.193.576.832.832,0,0,0,.254.618.874.874,0,0,0,.639.25,2.35,2.35,0,0,0,.5-.1,14.842,14.842,0,0,1,3.808-.44,18.082,18.082,0,0,1,3.839.4,11.144,11.144,0,0,1,3.144,1.13,1.036,1.036,0,0,0,.5.143.9.9,0,0,0,.627-.244.792.792,0,0,0,.266-.612Zm2.543,1.772a8.651,8.651,0,0,1-1.278,4.584,9.3,9.3,0,0,1-3.467,3.324,9.623,9.623,0,0,1-4.782,1.225,9.637,9.637,0,0,1-4.782-1.225,9.291,9.291,0,0,1-3.467-3.323A8.653,8.653,0,0,1,0,9.132,8.653,8.653,0,0,1,1.278,4.548,9.291,9.291,0,0,1,4.745,1.225,9.637,9.637,0,0,1,9.526,0a9.623,9.623,0,0,1,4.782,1.225,9.3,9.3,0,0,1,3.467,3.323,8.65,8.65,0,0,1,1.278,4.584Z"
      />
    </svg>
  );
}

export default SpotifyIcon;
